<template>
  <RouterView />
</template>

<script>
export default {
  name: 'App',
  components: {
    
  },
  mounted(){
    // 获取域名
    var domain = localStorage.getItem("hostname");
    if(!domain){
      const domain2 = window.location.hostname;
      localStorage.setItem("hostname",domain2);
    }
    // window.
    //setToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vd3d3LnN1YjAwMS5jb20vYXBpL3YxL2xvZ2luIiwiaWF0IjoxNzI5MTA4MDk4LCJleHAiOjE3MzEyNjgwOTgsIm5iZiI6MTcyOTEwODA5OCwianRpIjoiQkJaQW9LdEU0bVh2Yk5oeCIsInN1YiI6IjEiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.2wRbUsaHvapICnDEEERHt_rDgSHDnjqhu_9A3VM63F8')
    this.$store.dispatch('routes/addRouter')
    this.$router.beforeEach((to, from, next) => {
        if ( to.name != '/register' && to.name != '/login' && ( this.$store.getters.userInfo.token == '' || this.$store.getters.userInfo.token == 'undefined') ) {
          // 如果用户没有登录并且访问一个需要登录的页面，则重定向到登录页面
          next('/login');
        } else {
          // 否则，继续正常的导航
          next();
        }
    });
  
    this.getScrollbarHeight();
    this.$store.state.share_url = window.location.origin+"/#/register/"
  },
  methods : {
    getScrollbarHeight() {
      const height2 = window.innerHeight;
      const width2 = window.innerWidth;
        //const scrollbarEl = this.$refs.scrollbarRef.$el;
        //const height = scrollbarEl.offsetHeight || scrollbarEl.clientHeight;

        this.$store.state.scroll.height = height2-60;
        this.$store.state.scroll.width = width2;
    }
  }
}
</script>

<style lang="scss">

body,html{
    margin: 0;
    padding: 0;
}
.clicked:hover{
  cursor: pointer;
  font-weight: 550;
}
.main-box{
  margin:6px 0px;
}
.padding10{
  padding:10px;
}
.padding20{
  padding:20px
}
.margin10{
  margin:10px;
}
.margin20{
  margin:20px
}
.paddingtb10{
  padding-top: 10px;
  padding-bottom: 10px;
}
.paddinglr10{
  padding-right: 10px;
  padding-left:10px;
}
.paddinglr20{
  padding-right: 20px;
  padding-left:20px;
}
.margin-left{
  margin-left: 10px;
}
.font-left{
  text-align: left;
}
.flag1{
  color:#ea3d3d;
}
.flag2{
  color:#ffc710;
}
.flag3{
  color:#3acb36;
}
.flag4{
  color:#1a70f1;
}
.flag5{
  color:#ba2fff;
}
.flag6{
  color:#f48804;
}
.flag7{
  color:#41b4fa;
}
.flag8{
  color:#e5b6b6;
}
.flag9{
  color:#9ba217;
}
.flag10{
  color:#e4248e;
}
.border{
  border: 1px solid #dcdfe6;
  border-radius: 6px;
}
.white{
  color: #fff !important;;
}

.el-container{
  height: 100vh;
}

.el-header{
  background-image: linear-gradient(to bottom right, #2d3a5b 50%, #545c64 100%) 
}

.el-main{
//background-color: #eee;
}

.green{
  color: #3acb36;
}
.red{
  color:#ea3d3d;
}
.border{
  border: 1px solid #e2e2e2;
}
</style>
